import React, { Component } from 'react';
import { Toolbar } from 'react-md';

export default class detalhes extends Component {
  render() {
    const title = this.props.title;
    return <Toolbar
              id="fixed-toolbar-example"
              colored
              title={title}
              titleId="search-pastries">
          </Toolbar>;
  }
}
