import React, { Component } from 'react';
import { Avatar, List, ListItem } from 'react-md';
import { Link } from 'react-router-dom';
import { Toolbar } from 'react-md';

export default class Linhas extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true }
  }

  componentDidMount() {
    return fetch('https://api.meubusao.com.br/v3/linha')
      .then(response => response.json())
      .then((responseJson) => {
        
        this.setState({
          isLoading: false,
          dataSource: responseJson,
        }, function () {
          //console.log(responseJson);
        });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="container">
          <div className="center">
            <span>Carregando...</span>
          </div>
        </div>
      );
    } else {
      const linhas = this.state.dataSource;
      return (
        <>
          <Toolbar
              id="fixed-toolbar-example"
              colored
              title='LINHAS'
              titleId="search-pastries"
              fixed/>
          <List className="md-toolbar-relative">
            {
              linhas.map(item => (
                <Link to={{
                    pathname: 'linha',
                    state: {item: item}
                  }} >
                  <ListItem key={item.idLinha.toString()} id={item.idLinha} itemComponent="li"
                    leftAvatar={
                      <Avatar key={item.idLinha.toString()}
                        random>
                        <span style={{ fontSize: '0.7em', fontWeight: 'bold' }}>
                          {item.codigo}
                        </span>
                      </Avatar>
                    }
                    primaryText={item.nome}>
                  </ListItem>
                </Link>
              ))
            }
          </List>
        </>
      );
    }
  }

  hashCode(str) { // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  _intToRGB = (i) => {
    let c = (0x00FFFFFF & (MATERIAL[(Math.abs(i) % MATERIAL.length)]))
      .toString(16)
      .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  };
}

const MATERIAL = [
  -1739917,
  -1023342,
  -4560696,
  -6982195,
  -8812853,
  -10177034,
  -11549705,
  -11677471,
  -11684180,
  -8271996,
  -5319295,
  35429,
  -2825897,
  54607,
  46925,
  -6190977,
  -7297874];
