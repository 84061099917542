import React, { Component } from 'react';
import Linhas from './screens/linhas';
import LinhaDetalhes from './screens/detalhes';
import TabelaHorarios from './screens/horarios';
import { Switch, Route } from 'react-router-dom';

export default class App extends Component {

  render() {
    return (
      <div>
        <Switch>
          <Route path="/horarios" component={TabelaHorarios} />
          <Route path="/linha" component={LinhaDetalhes} />
          <Route path="/" component={Linhas} />
        </Switch>
      </div>
    );
  }

}
