import React, { Component } from 'react';
import { Toolbar, Grid, Cell, Subheader } from 'react-md';

export default class TabelaHorarios extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true }
  }

  componentDidMount() {
    const { item, sentido, parada } = this.props.location.state;
    const idParada = parada.idParada;
    const idLinha = item.idLinha;
    const idSentido = sentido.idSentido;

    const url = 'https://api.meubusao.com.br/v3/horarios/' + idParada + '/' + idLinha + '/' + idSentido;

    return fetch(url)
      .then(response => response.json())
      .then((responseJson) => {

        this.setState({
          item,
          isLoading: false,
          dataSource: responseJson,
        }, function () {
          //console.log(responseJson);
        });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  renderRow(titulo, horarios) {
    const row = (
      <div>
        <Grid>
          {
            horarios.map((item, index) => (
              <Cell key={index} size={1}>
                {item}
              </Cell>
            ))
          }
        </Grid>
      </div>
    );

    const nenhumHorario = (
      <div>
        <span>{'Nenhum horário'}</span>
      </div>
    );

    return (
      <div>
        <div>
          <h3>{titulo}</h3>
        </div>
        {(horarios && horarios.length > 0) ? row : nenhumHorario}
      </div>
    );
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="container">
          <div className="center">
            <span>Carregando...</span>
          </div>
        </div>
      );
    } else {
      const horarios = this.state.dataSource;

      return (
        <>
          <Toolbar
              id="fixed-toolbar-example"
              colored
              title={'Horários - ' + this.state.item.codigo + ' ' + this.state.item.nome}
              titleId="search-pastries"
              fixed />
          <div className="md-toolbar-relative">
            <div style={{padding: '1em', textAlign: 'center'}}>
              <div>{horarios.diaUtil ? this.renderRow('DIA ÚTIL', horarios.diaUtil) : ''}</div>
              <div>{horarios.sabado ? this.renderRow('SÁBADO', horarios.sabado) : ''}</div>
              <div>{horarios.domingo ? this.renderRow('DOMINGO', horarios.domingo) : ''}</div>
            </div>
          </div>
        </>
      );
    }
  }
}
