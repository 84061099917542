import React, { Component } from 'react';
import { TabsContainer, Tabs, Tab, FontIcon, List, ListItem } from 'react-md';
import { Link } from 'react-router-dom';
import Toolbar from '../../components/toolbar';

export default class LinhaDetalhe extends Component {

  constructor(props) {
    super(props);
    this.state = {
      routes: [],
      isLoading: true,
      item: {
        codigo: 0,
        nome: ''
      }
    }
  }

  componentDidMount() {
    const { item } = this.props.location.state;
    const url  = 'https://api.meubusao.com.br/v3/linha/' + item.idLinha;

    return fetch(url)
      .then(response => response.json())
      .then((responseJson) => {

        const routes = [];
        responseJson[0].sentidos.forEach((item) => {
          routes.push({
            title: item.sentido.nomeSentido,
            key: item.sentido.idSentido,
            paradas: item.paradas,
            sentido: item.sentido
          });
        });

        console.log(routes);

        this.setState({
          item,
          routes,
          isLoading: false,
        });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
      const rotas = this.state.routes;
      const title = this.state.item.codigo + ' ' + this.state.item.nome;
      return (
          <TabsContainer panelClassName="md-grid" 
            fixed
            colored
            toolbar={<Toolbar title={title} />}>
              {              
                <Tabs tabId="simple-tab"
                  mobile
                  inactiveTabClassName="md-text--secondary">
                  {
                    rotas.map(item => (
                      <Tab key={item.key} label={item.title}>
                        <List key={item.key} style={{width: '100%'}}>
                          {
                            item.paradas.map(parada => (
                              <Link 
                                key={parada.idParada} 
                                to={{
                                  pathname: 'horarios',
                                  state: {
                                    item: this.state.item,
                                    sentido: item.sentido, 
                                    parada: parada
                                  }
                                }}>
                                <ListItem key={parada.idParada} id={parada.idParada} itemComponent="li"
                                  leftIcon={<FontIcon key={parada.idParada}>place</FontIcon>}
                                  primaryText={parada.nome}>
                                </ListItem>
                              </Link>
                            ))
                          }
                        </List>
                      </Tab>
                    ))
                  }
                </Tabs>
              }
          </TabsContainer>
      );
  }
}
